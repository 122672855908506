import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEventBySlug } from '../services/EventService';

const EventPage = () => {
  const { slug } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const { data } = await getEventBySlug(slug);
        setEvent(data);
      } catch (err) {
        console.error("Failed to fetch event:", err);
      }
    };

    fetchEvent();
  }, [slug]);

  if (!event) {
    return <p>Loading event details...</p>;
  }

  return (
    <div className="container">
      <h1>{event.eventName}</h1>
      <p>{event.description}</p>
      <p>Event Date: {new Date(event.eventDate).toLocaleDateString()}</p>
      {event.image && (
  <img src={`http://localhost:8080/${event.image}`} alt={event.eventName} />
)}

      <p>Location: {event.location}</p>
    </div>
  );
};

export default EventPage;
