import axios from 'axios';

// const API_URL = "https://devaa.ptoadmin.org/api/fundraising"

const API_URL = "https://api/fundraising";

export const getAllFundraising = () => { 
    return axios.get(API_URL);
};

export const getFundraisingBySlug = (slug) => { 
    return axios.get(`${API_URL}/${slug}`);
};

export const createFundraising = (fundraising) => {
    return axios.post(API_URL, fundraising);
};

