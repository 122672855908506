import React, { useEffect, useState } from 'react';
import { getAllAnnouncements } from '../services/AnnouncementService';

const AnnouncementList = () => {
  const [announcements, setAnnouncements] = useState([]);

  // Reusable function to fetch announcements
  const fetchData = async () => {
    try {
      const { data } = await getAllAnnouncements();
      console.log('Fetched Data:', data);  // Debugging log to check fetched data
      setAnnouncements(data || []);  // Set the announcements directly from the API response
    } catch (err) {
      console.error("API call failed:", err);
    }
  };

  // Fetch all announcements when the component loads
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="text-center">
        <h1>Announcements</h1>
        <p>Please check the PTO bi-weekly communications for more details.</p>
      </div>

      {/* Announcement list */}
      <div className="container text-center">
        <div className="row">
          {announcements.length > 0 ? announcements.map((announcement) => (
            <div className="col-md-6 mb-4" key={announcement.announcement_id}>
              <h2 className="text-primary">{announcement.title}</h2>
              <p>{announcement.content}</p>
              {announcement.published_date && (
                <p>Published on: {new Date(announcement.published_date).toLocaleDateString()}</p>
              )}
              {announcement.expiry_date && (
                <p>Expires on: {new Date(announcement.expiry_date).toLocaleDateString()}</p>
              )}
            </div>
          )) : (
            <div>No announcements to show.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementList;
