import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AnnouncementList from './components/AnnouncementList';
import EventList from './components/EventsList';
import WelcomeSection from './components/WelcomeSection';
import AboutPage from './components/AboutPage';
import EventPage from './components/EventPage';
import Navbar from './components/Navbar';  
import FundraisingOverview from './components/FundraisingOverview';  
import FundraisingPage from './components/FundraisingPage';  
import MembershipPage from './components/MembershipPage';  
import Footer from './components/Footer'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';  
import AdminDashboard from './components/AdminDashboard';  // Import Admin Dashboard
import AddEvents from './components/AddEvents';  // Import AddEvents component

function App() {
  return (
    <Router>
      {/* Navbar will appear on all pages except login */}
      <Routes>
        {/* Login Route */}
        <Route path="/login" element={<Login />} />

        {/* Home Route */}
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <WelcomeSection />
              <AnnouncementList />
              <EventList />
              <Footer />
            </>
          }
        />

        {/* About Page Route */}
        <Route
          path="/about"
          element={
            <>
              <Navbar />
              <AboutPage />
              <Footer />
            </>
          }
        />

        {/* Event Page Route with slug */}
        <Route
          path="/events/:slug"
          element={
            <>
              <Navbar />
              <EventPage />
              <Footer />
            </>
          }
        />

        {/* Fundraising Overview Route */}
        <Route
          path="/fundraising"
          element={
            <>
              <Navbar />
              <FundraisingOverview />
              <Footer />
            </>
          }
        />

        {/* Fundraising Event Page Route with slug */}
        <Route
          path="/fundraising/:slug"
          element={
            <>
              <Navbar />
              <FundraisingPage />
              <Footer />
            </>
          }
        />

        {/* Membership Page Route */}
        <Route
          path="/membership"
          element={
            <>
              <Navbar />
              <MembershipPage />
              <Footer />
            </>
          }
        />

        {/* Admin Dashboard Route */}
        <Route
          path="/admin-dashboard"
          element={
            <>
              <Navbar />
              <AdminDashboard />  {/* Admin Dashboard Page */}
              <Footer />
            </>
          }
        />

        {/* Add Event Page Route for Admins */}
        <Route
          path="/admin/add-event"
          element={
            <>
              <Navbar />
              <AddEvents />  {/* Add Events Page for Admin */}
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
